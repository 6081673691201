import React from 'react';
import { useTranslation } from 'react-i18next';
import Credits from './Credits';
import Studio from './Studio';
import Contact from './Contact';
import Lessons from './Lessons';
import Shop from './Shop';
import CarouselSimple from '../components/CarouselSimple';

const Home: React.FC = () => {
  const { t } = useTranslation();

  const items = [
    {
      src: '/img/home/01.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/03.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/06.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/07.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/09.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/10.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/11.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/12.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/15.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/16.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/17.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/18.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/19.webp',
      alt: 'Diogo Leite - Drummer',
    },
    {
      src: '/img/home/20.webp',
      alt: 'Diogo Leite - Drummer',
    },
  ];
  return (
    <div className="homepage">
      <section id="about">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6">
              <CarouselSimple items={items} height="100%" width="100%" />
            </div>

            <div className="col-12 col-lg-6">
              <div className="bio-title mt-5 mt-lg-0">
                <h3>Diogo Leite</h3>
              </div>
              <div className="bio-text">
                <p>{t('about1')}</p>
                <p>{t('about2')}</p>
                <p>{t('about3')}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Credits />
      <Studio />
      <Lessons />
      <Shop />
      <Contact />
    </div>
  );
};

export default Home;
