import { useTranslation } from 'react-i18next';
import CarouselSimple from '../components/CarouselSimple';
import { email } from '../index';

const Shop = () => {
  const { t } = useTranslation();

  const items = [
    {
      src: '/img/store/book1/Introdução a Bateria - O Manual I.webp',
      alt: 'Introdução à Bateria - O Manual I',
    },
    {
      src: '/img/store/book1/Introdução a Bateria - O Manual I - 12-14.webp',
      alt: 'Introdução à Bateria - O Manual I - 12-14',
    },
    {
      src: '/img/store/book1/Introdução a Bateria - O Manual I - 38-39.webp',
      alt: 'Introdução à Bateria - O Manual I - 38-39',
    },
  ];

  return (
    <section id="shop">
      <div className="container">
        <div className="row">
          <div className="col-12"></div>
          <div className="col-12 col-lg-6">
            <div className="">
              <CarouselSimple
                className="shop-carousel"
                showDots
                items={items}
                height="100%"
                width="100%"
              />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="bio-title">
              <h3>{t('shopTitle')}</h3>
            </div>
            <h4>{t('product1')}</h4>
            <p>{t('product1Description1')}</p>
            <p>{t('product1Description2')}</p>
            <p>{t('product1Description3')}</p>
            <p>{t('product1Description4')}</p>
            <p>{t('product1Description5')}</p>
          </div>
          <div className="col-12 d-flex flex-column justify-content-end align-items-end gap-4 mt-5">
            <div className="d-flex flex-row align-items-center gap-4">
              <h3 className="text-dark mb-0">19€</h3>
              <a
                href={`mailto:${email}?subject=Encomendar%20%22Introdu%C3%A7%C3%A3o%20%C3%A0%20Bateria%20-%20O%20Manual%20I%22&body=Ol%C3%A1%20Diogo.%0AQuero%20encomendar%20o%20livro%20%22Introdu%C3%A7%C3%A3o%20%C3%A0%20Bateria%20-%20O%20Manual%20I%22`}
                className="btn btn-lg btn-dark px-5 text-uppercase"
              >
                {t('order')}
              </a>
            </div>
            <p>
              Ou envia email para:{' '}
              <a
                className="text-body"
                href={`mailto:${email}?subject=Encomendar%20%22Introdu%C3%A7%C3%A3o%20%C3%A0%20Bateria%20-%20O%20Manual%20I%22&body=Ol%C3%A1%20Diogo.%0AQuero%20encomendar%20o%20livro%20%22Introdu%C3%A7%C3%A3o%20%C3%A0%20Bateria%20-%20O%20Manual%20I%22`}
              >
                {email}
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Shop;
