const SocialsLessons = () => {
  return (
    <div className="social-links-lessons social-links">
      <a
        target="_blank"
        title="Instagram The House of Drummers"
        href="https://www.instagram.com/the_house_of_drummers_/"
        rel="noreferrer"
      >
        <i className="fa fa-instagram" aria-hidden="true"></i>
      </a>
      <a
        target="_blank"
        title="Facebook The House of Drummers"
        href="https://www.facebook.com/houseofDrummers/"
        rel="noreferrer"
      >
        <i className="fa fa-facebook" aria-hidden="true"></i>
      </a>

      <a
        target="_blank"
        title="Youtube The House of Drummers"
        href="https://www.youtube.com/@thehouseofdrummers3847"
        rel="noreferrer"
      >
        <i className="fa fa-youtube-play" aria-hidden="true"></i>
      </a>
      <a
        target="_blank"
        title="Youtube Guitar Drum Festival"
        href="https://www.youtube.com/@guitardrumfestival7995"
        rel="noreferrer"
      >
        <i className="fa fa-youtube-play" aria-hidden="true"></i>
      </a>
    </div>
  );
};

export default SocialsLessons;
