import { useTranslation } from 'react-i18next';
import { email } from '../index';
const Contact = () => {
  const { t } = useTranslation();

  return (
    <section id="contact">
      <div className="container">
        <div className="row">
          <div className="col">
            <h3 className="text-body">{t('contactTitle')}</h3>
            <p>
              {t('contactDescription')}{' '}
              <a className="text-body" href={`mailto:${email}`}>
                {email}
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
