import React from 'react';
import Masonry, { ResponsiveMasonry } from 'react-responsive-masonry';
import { useTranslation } from 'react-i18next';
import Image from '../components/Image';

interface Credit {
  image: string;
  alt: string;
}

const credits: Credit[] = [
  {
    image: `/img/credits/Workshop.webp`,
    alt: `Workshop`,
  },
  {
    image: `/img/credits/Vater.webp`,
    alt: `Vater`,
  },
  {
    image: `/img/credits/Premier Drum Co.webp`,
    alt: `Premier Drum Co`,
  },
  {
    image: `/img/credits/Masterclass.webp`,
    alt: `Masterclass`,
  },
  {
    image: `/img/credits/GO’EL 2019.webp`,
    alt: `GO’EL 2019`,
  },
  {
    image: '/img/credits/Alesis Tour 2016.webp',
    alt: 'Alesis Tour 2016',
  },
  {
    image: '/img/credits/Alesis Tour 2017.webp',
    alt: 'Alesis Tour 2017',
  },
  {
    image: '/img/credits/Dia do Ritmo 2018.webp',
    alt: 'Dia do Ritmo 2018',
  },
  {
    image: '/img/credits/livro-introducao-bateria-2015.webp',
    alt: 'Livro "Introdução à bateria" 2015',
  },
  {
    image: '/img/credits/Musical 2018.webp',
    alt: 'Unhas Negras - O Espetáculo',
  },
  {
    image: '/img/credits/Musical 2022.webp',
    alt: 'Musical 2022',
  },
  {
    image: '/img/credits/Porto Drum Show 2019.webp',
    alt: 'Porto Drum Show 2019',
  },
  {
    image: '/img/credits/2009 REVISTA BATERIA TOTAL.webp',
    alt: 'Revista Bateria Total 2019',
  },
  {
    image: `/img/credits/Drummers World Record.webp`,
    alt: 'Drummers World Record',
  },
  {
    image: `/img/credits/Paiste Article.webp`,
    alt: `Paiste Article`,
  },
  {
    image: `/img/credits/Paiste.webp`,
    alt: `Paiste`,
  },
];

const Credits: React.FC = () => {
  const { t } = useTranslation();

  return (
    <section id="credits">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h3>{t('creditsTitle')}</h3>
          </div>
        </div>

        {credits.length > 0 && (
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 576: 1, 768: 2, 960: 3, 1140: 4 }}
          >
            <Masonry gutter="30px">
              {credits?.map((credit, index) => (
                <div key={index} className="grid-item">
                  <a
                    className="card"
                    href={credit.image}
                    target="_blank"
                    aria-label={credit.alt}
                    rel="noopener noreferrer"
                  >
                    <Image
                      src={credit.image}
                      alt={credit.alt}
                      className="img-fluid w-100"
                    />
                  </a>
                </div>
              ))}
            </Masonry>
          </ResponsiveMasonry>
        )}
      </div>
    </section>
  );
};

export default Credits;
