import { useTranslation } from 'react-i18next';
import Image from '../components/Image';
import CarouselSimple from '../components/CarouselSimple';
import SocialsLessons from '../components/SocialsLessons';

const Lessons = () => {
  const { t } = useTranslation();

  const items = [
    {
      src: '/img/lessons/The House of Drummers - 1.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 3.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 5.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 6.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 7.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 9.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 10.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 11.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 12.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 13.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 14.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 15.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 16.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 19.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 20.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 21.webp',
      alt: 'The House of Drummers',
    },
    {
      src: '/img/lessons/The House of Drummers - 22.webp',
      alt: 'The House of Drummers',
    },
  ];

  return (
    <section id="studio">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 text-center">
            <CarouselSimple items={items} height="100%" width="100%" />
          </div>

          <div className="col-12 col-lg-6">
            <div className="bio-title mt-5 mt-lg-0">
              <h3>{t('lessonsTitle')}</h3>
            </div>
            <div className="bio-text mb-2">
              <h6>{t('lessons1')}</h6>
              <p>{t('lessons2')}</p>
              <p>{t('lessons3')}</p>
            </div>

            <Image
              src="/img/The House of Drummers.png"
              className="img-fluid mb-2"
              alt="The House 5tudi0"
              style={{ maxWidth: '150px' }}
            />

            <SocialsLessons />
          </div>

          <div className="col-12 mt-3">
            <div className="ratio ratio-16x9">
              <iframe
                src="https://www.youtube.com/embed/videoseries?si=TDoeGBk7y0n7J69z&amp;list=PL-woEjKC4TMqGeZUzl_wsIK3uSdVUZ2j3"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Lessons;
