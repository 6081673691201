const Socials = () => {
  return (
    <div className="social-links">
      <a
        target="_blank"
        title="Facebook"
        href="https://facebook.com/diogoleitedrummer"
        rel="noreferrer"
      >
        <i className="fa fa-facebook" aria-hidden="true"></i>
      </a>
      <a
        target="_blank"
        title="Instagram"
        href="https://www.instagram.com/diogo_leite_"
        rel="noreferrer"
      >
        <i className="fa fa-instagram" aria-hidden="true"></i>
      </a>
      <a
        target="_blank"
        title="Youtube"
        href="https://www.youtube.com/diogohleite"
        rel="noreferrer"
      >
        <i className="fa fa-youtube-play" aria-hidden="true"></i>
      </a>
      <a
        target="_blank"
        title="Bandcamp"
        href="https://diogoleite.bandcamp.com/"
        rel="noreferrer"
      >
        <i className="fa fa-bandcamp" aria-hidden="true"></i>
      </a>
    </div>
  );
};

export default Socials;
