import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { IImage } from '../types/types';

const Image: React.FC<IImage> = ({
  src,
  alt,
  className,
  height,
  width,
  ...props
}) => (
  <div>
    <LazyLoadImage
      loading="lazy"
      className={className}
      src={src}
      alt={alt}
      effect="blur"
      height={height}
      width={width}
      wrapperClassName="w-100 h-100"
      {...props}
    />
  </div>
);

export default Image;
