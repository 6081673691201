import { Helmet, HelmetProvider } from 'react-helmet-async';

interface MetaTagsProps {
  title: string;
  description: string;
  image: string;
  url: string;
  type?: string;
  siteName?: string;
  twitterHandle?: string;
}

const MetaTags = ({
  title,
  description,
  image,
  url,
  type = 'website',
  siteName = 'Diogo Leite',
  twitterHandle = '@diogo_leite_', // Replace with actual Twitter handle
}: MetaTagsProps) => {
  // Ensure image URL is absolute
  const absoluteImageUrl = image.startsWith('http')
    ? image
    : `${window.location.origin}${image}`;

  return (
    <HelmetProvider>
      <Helmet>
        {/* Standard Meta Tags */}
        <title>{title}</title>
        <meta name="description" content={description} />
        <link rel="canonical" href={url} />

        {/* Open Graph / Facebook */}
        <meta property="og:type" content={type} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content={absoluteImageUrl} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:site_name" content={siteName} />

        {/* Twitter */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={twitterHandle} />
        <meta name="twitter:creator" content={twitterHandle} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={absoluteImageUrl} />
      </Helmet>
    </HelmetProvider>
  );
};

export default MetaTags;
